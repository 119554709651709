.backdrop {
    position: fixed;
    inset: 0;
    background-color: var(--main-backdrop-background-color);
    opacity: 1;
    visibility: visible;
    transition-duration: var(--duration);
    transition-property: opacity, visibility;
    pointer-events: none;

    display: none;
}

.container {
    border-radius: var(--border-radius);
    width: calc(var(--spacing) * 100);
    background-repeat: no-repeat;
    background-attachment: fixed;

    --R: 80px;

    --width: calc(var(--R) * 8);
}

@media (max-width: 599px) {
    .container {
        width: 100%;
        --width: 100%;
    }
}

.container h1 {
    padding: calc(var(--spacing) * 3) var(--spacing);
    text-align: center;
}

.container h2 {
    font-size: 3em;
    text-align: center;
    margin-bottom: calc(var(--spacing) * 5);
}

.container.p-1 {
    background-color: var(--main-background-color);
    background-image: radial-gradient(at 0% 0%, var(--sphere-p-1-color) 0px, var(--dd) 50%);
}

.container.p-2 {
    background-color: var(--main-background-color);
    background-image: radial-gradient(at 0% 0%, var(--sphere-p-2-color) 0px, var(--dd) 50%);
}

.container.p-3 {
    background-color: var(--main-background-color);
    background-image: radial-gradient(at 0% 0%, var(--sphere-p-3-color) 0px, var(--dd) 50%);
}

.container.p-4 {
    background-color: var(--main-background-color);
    background-image: radial-gradient(at 0% 0%, var(--sphere-p-4-color) 0px, var(--dd) 50%);
}

.wrap {
    display: grid;
    grid-template-columns: calc(3 * var(--R)) 1fr;
    gap: 0 calc(var(--spacing) * 3);
    grid-template-areas: "sphere score" "sphere stones";
    width: var(--width);
    margin: 0 auto;
}

.sphereWrap {
    width: calc(3 * var(--R));
    grid-area: sphere;
}

.score {
    position: static;
    inset: auto;
    transform: translate(0, 0);
    grid-area: score;
    margin-bottom: calc(var(--R) * 0.01);
}

.stonesWrap {
    display: flex;
    flex-wrap: wrap;
    grid-area: stones;
}

.stone {
    position: static;
    inset: auto;
    width: auto;
    height: auto;
}

.stone::before {
    --s: 0.75;
}

.stoneAlt::before {
    --s: 0.4;
}

.othersWrap {
    width: var(--width);
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    gap: 6%;
}

.othersWrap > div {
    width: 33%;
    min-height: calc(var(--R) * 4);
    display: grid;
    align-content: flex-start;
    gap: 0 var(--spacing);
    grid-template-areas: "otherSphere otherScore" "otherSphere otherStones";
}

.otherSphere {
    width: var(--R);
    grid-area: otherSphere;
}

.otherScore {
    font-size: calc(var(--R) * 0.25);
    grid-area: otherScore;
    width: calc(var(--R) * 0.4);
    height: calc(var(--R) * 0.4);
    margin-bottom: calc(var(--R) * 0.25);
}

.otherStones {
    display: flex;
    flex-wrap: wrap;
    grid-area: otherStones;
}

.actions {
    width: var(--width);
    margin: var(--R) auto;
    padding: var(--spacing);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: calc(var(--spacing) * 2);
}
