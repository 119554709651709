.root {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.container {
    position: absolute;
    top: 0;
    left: 0;
    --s: calc(var(--R) * 2);
    transition-duration: var(--duration);
    transition-property: transform, width, height;

    --XX: 0;
    --YY: 0;

    transform: translate(-50%, -50%) translate(var(--XX), var(--YY));
}

:global(.flat) .container {
    width: var(--s);
    height: calc(var(--s) * var(--ratio));
}

:global(.pointy) .container {
    width: calc(var(--s) * var(--ratio));
    height: var(--s);
}

.inner {
    display: flex;
    gap: calc(var(--R) * 0.1);
    position: absolute;
    left: 50%;
    top: calc(100% + var(--R) * 0.1);
    transform: translateX(-50%);
}

.button {
    composes: button from "../../Components/Button/Button.module.css";
    composes: buttonContained from "../../Components/Button/Button.module.css";
    composes: withIcon from "../../Components/Button/Button.module.css";

    --bg-icon: none;

    --a: var(--R);
    --p: calc(var(--a) * 0.1);

    --m: calc(var(--R) * 0.1);
    --top: calc(50% + var(--m));

    padding: var(--p);
    border-radius: calc(var(--a) * 0.15);
    font-size: calc(var(--a) * 0.25);
    display: flex;
    flex-direction: column;
}

.button::after {
    display: none;
}

.button::before {
    margin: 0;
}

.button::before {
    --size: var(--a);
    background-image: var(--bg-icon);
}

.apply, .cancel, .left, .right {
    composes: button;
}

.apply {
    --bg-icon: var(--apply-button-icon);
}

.cancel {
    --bg-icon: var(--cancel-button-icon);
}

.left {
    --bg-icon: var(--rotate-left-icon);
}

.right {
    --bg-icon: var(--rotate-right-icon);
}
