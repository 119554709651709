.item {
    --S: calc(var(--R) * 2.5);
    --x: calc(var(--S) * 0.5);
    --X: calc(var(--x) * -1);
    --Y: var(--S);
    --y-margin: 0.1;

    pointer-events: var(--pointer-events);
    position: absolute;
    top: 0;
    left: 50%;
    transition-property: width, height, transform;
    transition-duration: var(--duration);
    width: var(--S);
    height: var(--S);
    transform: translate(var(--X), var(--Y));
}

:global(.flat) .item.p-1 {
    --X: calc(var(--x) * -7);
    --Y: calc(var(--S) * var(--y-margin));
}

:global(.pointy) .item.p-1 {
    --X: calc(var(--x) * -6);
    --Y: calc(var(--S) * var(--y-margin));
}

:global(.flat) .item.p-2 {
    --X: calc(var(--x) * 5);
    --Y: calc(var(--S) * var(--y-margin));
}

:global(.pointy) .item.p-2 {
    --X: calc(var(--x) * 4);
    --Y: calc(var(--S) * var(--y-margin));
}

:global(.flat) .item.p-3 {
    --X: calc(var(--x) * 5);
    --Y: calc(var(--S) * (5 + var(--y-margin)));
}

:global(.pointy) .item.p-3 {
    --X: calc(var(--x) * 4);
    --Y: calc(var(--S) * 6);
}

:global(.flat) .item.p-4 {
    --X: calc(var(--x) * -7);
    --Y: calc(var(--S) * (5 + var(--y-margin)));
}

:global(.pointy) .item.p-4 {
    --X: calc(var(--x) * -6);
    --Y: calc(var(--S) * 6);
}

/** - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */

.hex {
    --s: calc(var(--R) * 1.5);
    pointer-events: var(--pointer-events);
    background-repeat: no-repeat;
    background-size: contain;
    filter: drop-shadow(0 0 1em rgba(68, 68, 68, .08));
    width: calc(var(--s));
    height: calc(var(--s) * var(--ratio));
    position: absolute;
    top: 0;
    left: 50%;
    transition-property: width, height, transform;
    transition-duration: var(--duration);
}

:global(.flat) .hex {
    transform: translate(var(--X), var(--Y));
}

:global(.pointy) .hex {
    transform: translate(var(--X), var(--Y)) rotate(-30deg);
}

:global(.flat) .hex.p-1 {
    --X: calc(var(--R) * -8.95);
    --Y: calc(var(--R) * 4.25);
}

:global(.pointy) .hex.p-1 {
    --X: calc(var(--R) * -4.75);
    --Y: calc(var(--R) * 0.25);
}

:global(.flat) .hex.p-2 {
    --X: calc(var(--R) * 7.45);
    --Y: calc(var(--R) * 4.25);
}

:global(.pointy) .hex.p-2 {
    --X: calc(var(--R) * 3.25);
    --Y: calc(var(--R) * 0.25);
}

:global(.flat) .hex.p-3 {
    --X: calc(var(--R) * 7.25);
    --Y: calc(var(--R) * 11.25);
}

:global(.pointy) .hex.p-3 {
    --X: calc(var(--R) * 3.25);
    --Y: calc(var(--R) * 16.5);
}

:global(.flat) .hex.p-4 {
    --X: calc(var(--R) * -8.75);
    --Y: calc(var(--R) * 11.25);
}

:global(.pointy) .hex.p-4 {
    --X: calc(var(--R) * -4.75);
    --Y: calc(var(--R) * 16.5);
}

/** - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */

.score {
    pointer-events: var(--pointer-events);
    position: absolute;
    top: 0;
    left: 50%;
    transition-property: width, height, transform;
    transition-duration: var(--duration);

    --x: 0;
    --y: 0;

    transform: translate(var(--x), var(--y)) translate(-50%, 0);
    font-size: calc(var(--R) * 0.45);

    width: calc(var(--R) * 0.7);
    height: calc(var(--R) * 0.7);

    background-color: rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 var(--spacing) rgba(0, 0, 0, 1);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

:global(.flat) .score.p-1 {
    --x: calc(var(--R) * -5.4);
    --y: calc(var(--R) * 0.15);
}

:global(.pointy) .score.p-1 {
    --x: calc(var(--R) * -7.25);
    --y: calc(var(--R) * 3.25);
}

:global(.flat) .score.p-2 {
    --x: calc(var(--R) * 5.4);
    --y: calc(var(--R) * 0.15);
}

:global(.pointy) .score.p-2 {
    --x: calc(var(--R) * 7.25);
    --y: calc(var(--R) * 3.25);
}

/** - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */

.stone {
    position: absolute;
    top: 0;
    left: 50%;

    --size: calc(var(--R) * 0.6);
    --x-shift: 5.2;
    --x-ad: 0;
    --x-reverse: 1;
    --x: calc(var(--R) * var(--x-shift) * var(--x-reverse) + var(--size) * var(--x-ad) * var(--x-reverse));
    --y-ad: 0;
    --y: calc(var(--R) + var(--size) * var(--y-ad));
    transform: translate(var(--x), var(--y)) translate(-50%, 0);
    width: var(--size);
    height: var(--size);
}

.stone > div {
    width: auto;
    height: auto;
    top: unset;
    left: unset;
    position: relative;
}

.stone > div::before {
    --s: 0.75;
}

:global(.flat) .stone > div[class*="emerald"]::before {
    --s: 0.5;
    transform: rotate(60deg);
}


.stone.p-1 {
    --x-reverse: -1;
}

.stone.p-2 {

}

:global(.flat) .stone.s-1 {
    --x-ad: 1.75;
    --y-ad: 2;
}

:global(.flat) .stone.s-2 {
    --x-ad: 1.2;
    --y-ad: 1;
}

:global(.flat) .stone.s-3 {
    --x-ad: 2.6;
    --y-ad: 2.8;
}

:global(.flat) .stone.s-4 {
    --x-ad: 3.7;
    --y-ad: 3.1;
}

:global(.flat) .stone.s-5 {
    --x-ad: 1.3;
    --y-ad: -0.2;
}

:global(.flat) .stone.s-6 {
    --x-ad: 4.8;
    --y-ad: 2.9;
}

:global(.flat) .stone.s-7 {
    --x-ad: 5.7;
    --y-ad: 2.3;
}

:global(.flat) .stone.s-8 {
    --x-ad: 1.8;
    --y-ad: -1.3;
}

:global(.flat) .stone.s-9 {
    --x-ad: 3;
    --y-ad: 3.75;
}

:global(.flat) .stone.s-10 {
    --x-ad: 4.2;
    --y-ad: 4;
}

:global(.flat) .stone.s-11 {
    --x-ad: 5.2;
    --y-ad: 3.8;
}

:global(.flat) .stone.s-12 {
    --x-ad: 3.5;
    --y-ad: 4.6;
}
