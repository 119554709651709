.pointy [data-qr="6,-3"] { transform: translate(calc(6.794228634059948 * var(--R)), calc(3.6339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="-6,3"] { transform: translate(calc(-8.794228634059948 * var(--R)), calc(12.6339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="-3,-3"] { transform: translate(calc(-8.794228634059948 * var(--R)), calc(3.6339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="3,-6"] { transform: translate(calc(-1 * var(--R)), calc(-0.8660254 * var(--R))) rotate(-30deg); }
.pointy [data-qr="3,3"] { transform: translate(calc(6.794228634059948 * var(--R)), calc(12.6339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="-3,6"] { transform: translate(calc(-1 * var(--R)), calc(17.1339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="-1,-4"] { transform: translate(calc(-6.196152422706632 * var(--R)), calc(2.1339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="1,-5"] { transform: translate(calc(-3.5980762113533156 * var(--R)), calc(0.6339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="4,-5"] { transform: translate(calc(1.598076211353316 * var(--R)), calc(0.6339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="5,-4"] { transform: translate(calc(4.196152422706631 * var(--R)), calc(2.1339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="5,-1"] { transform: translate(calc(6.794228634059947 * var(--R)), calc(6.6339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="4,1"] { transform: translate(calc(6.794228634059947 * var(--R)), calc(9.6339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="1,4"] { transform: translate(calc(4.196152422706632 * var(--R)), calc(14.1339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="-1,5"] { transform: translate(calc(1.5980762113533156 * var(--R)), calc(15.6339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="-4,5"] { transform: translate(calc(-3.598076211353316 * var(--R)), calc(15.6339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="-5,4"] { transform: translate(calc(-6.196152422706631 * var(--R)), calc(14.1339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="-5,1"] { transform: translate(calc(-8.794228634059948 * var(--R)), calc(9.6339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="-4,-1"] { transform: translate(calc(-8.794228634059948 * var(--R)), calc(6.6339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="-5,2"] { transform: translate(calc(-7.928203230275509 * var(--R)), calc(11.1339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="-5,3"] { transform: translate(calc(-7.0621778264910695 * var(--R)), calc(12.6339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="-2,-3"] { transform: translate(calc(-7.06217782649107 * var(--R)), calc(3.6339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="-3,-2"] { transform: translate(calc(-7.928203230275509 * var(--R)), calc(5.1339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="2,-5"] { transform: translate(calc(-1.8660254037844384 * var(--R)), calc(0.6339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="3,-5"] { transform: translate(calc(-0.13397459621556074 * var(--R)), calc(0.6339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="5,-3"] { transform: translate(calc(5.0621778264910695 * var(--R)), calc(3.6339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="5,-2"] { transform: translate(calc(5.928203230275509 * var(--R)), calc(5.1339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="3,2"] { transform: translate(calc(5.928203230275509 * var(--R)), calc(11.1339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="2,3"] { transform: translate(calc(5.06217782649107 * var(--R)), calc(12.6339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="-3,5"] { transform: translate(calc(-1.8660254037844393 * var(--R)), calc(15.6339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="-2,5"] { transform: translate(calc(-0.13397459621556163 * var(--R)), calc(15.6339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="4,0"] { transform: translate(calc(5.928203230275509 * var(--R)), calc(8.1339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="-4,0"] { transform: translate(calc(-7.928203230275509 * var(--R)), calc(8.1339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="-4,4"] { transform: translate(calc(-4.464101615137754 * var(--R)), calc(14.1339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="0,-4"] { transform: translate(calc(-4.464101615137754 * var(--R)), calc(2.1339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="0,4"] { transform: translate(calc(2.4641016151377544 * var(--R)), calc(14.1339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="4,-4"] { transform: translate(calc(2.4641016151377544 * var(--R)), calc(2.1339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="0,0"] { transform: translate(calc(-1 * var(--R)), calc(8.1339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="-4,1"] { transform: translate(calc(-7.06217782649107 * var(--R)), calc(9.6339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="-4,2"] { transform: translate(calc(-6.196152422706632 * var(--R)), calc(11.1339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="-4,3"] { transform: translate(calc(-5.330127018922193 * var(--R)), calc(12.6339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="-3,-1"] { transform: translate(calc(-7.06217782649107 * var(--R)), calc(6.6339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="-3,0"] { transform: translate(calc(-6.196152422706632 * var(--R)), calc(8.1339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="-3,1"] { transform: translate(calc(-5.330127018922194 * var(--R)), calc(9.6339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="-3,2"] { transform: translate(calc(-4.464101615137755 * var(--R)), calc(11.1339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="-3,3"] { transform: translate(calc(-3.598076211353316 * var(--R)), calc(12.6339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="-3,4"] { transform: translate(calc(-2.7320508075688776 * var(--R)), calc(14.1339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="-2,-2"] { transform: translate(calc(-6.196152422706632 * var(--R)), calc(5.1339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="-2,-1"] { transform: translate(calc(-5.330127018922193 * var(--R)), calc(6.6339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="-2,0"] { transform: translate(calc(-4.464101615137754 * var(--R)), calc(8.1339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="-2,1"] { transform: translate(calc(-3.598076211353316 * var(--R)), calc(9.6339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="-2,2"] { transform: translate(calc(-2.732050807568877 * var(--R)), calc(11.1339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="-2,3"] { transform: translate(calc(-1.8660254037844384 * var(--R)), calc(12.6339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="-2,4"] { transform: translate(calc(-1 * var(--R)), calc(14.1339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="-1,-3"] { transform: translate(calc(-5.330127018922193 * var(--R)), calc(3.6339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="-1,-2"] { transform: translate(calc(-4.464101615137754 * var(--R)), calc(5.1339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="-1,-1"] { transform: translate(calc(-3.598076211353316 * var(--R)), calc(6.6339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="-1,0"] { transform: translate(calc(-2.732050807568877 * var(--R)), calc(8.1339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="-1,1"] { transform: translate(calc(-1.8660254037844386 * var(--R)), calc(9.6339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="-1,2"] { transform: translate(calc(-1 * var(--R)), calc(11.1339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="-1,3"] { transform: translate(calc(-0.13397459621556118 * var(--R)), calc(12.6339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="-1,4"] { transform: translate(calc(0.7320508075688772 * var(--R)), calc(14.1339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="0,-3"] { transform: translate(calc(-3.598076211353316 * var(--R)), calc(3.6339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="0,-2"] { transform: translate(calc(-2.732050807568877 * var(--R)), calc(5.1339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="0,-1"] { transform: translate(calc(-1.8660254037844386 * var(--R)), calc(6.6339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="0,1"] { transform: translate(calc(-0.1339745962155614 * var(--R)), calc(9.6339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="0,2"] { transform: translate(calc(0.7320508075688772 * var(--R)), calc(11.1339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="0,3"] { transform: translate(calc(1.598076211353316 * var(--R)), calc(12.6339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="1,-4"] { transform: translate(calc(-2.732050807568877 * var(--R)), calc(2.1339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="1,-3"] { transform: translate(calc(-1.8660254037844388 * var(--R)), calc(3.6339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="1,-2"] { transform: translate(calc(-1 * var(--R)), calc(5.1339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="1,-1"] { transform: translate(calc(-0.1339745962155614 * var(--R)), calc(6.6339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="1,0"] { transform: translate(calc(0.7320508075688772 * var(--R)), calc(8.1339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="1,1"] { transform: translate(calc(1.598076211353316 * var(--R)), calc(9.6339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="1,2"] { transform: translate(calc(2.4641016151377544 * var(--R)), calc(11.1339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="1,3"] { transform: translate(calc(3.3301270189221928 * var(--R)), calc(12.6339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="2,-4"] { transform: translate(calc(-1 * var(--R)), calc(2.1339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="2,-3"] { transform: translate(calc(-0.13397459621556163 * var(--R)), calc(3.6339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="2,-2"] { transform: translate(calc(0.7320508075688772 * var(--R)), calc(5.1339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="2,-1"] { transform: translate(calc(1.598076211353316 * var(--R)), calc(6.6339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="2,0"] { transform: translate(calc(2.4641016151377544 * var(--R)), calc(8.1339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="2,1"] { transform: translate(calc(3.3301270189221928 * var(--R)), calc(9.6339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="2,2"] { transform: translate(calc(4.196152422706632 * var(--R)), calc(11.1339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="3,-3"] { transform: translate(calc(1.598076211353316 * var(--R)), calc(3.6339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="3,-4"] { transform: translate(calc(0.7320508075688776 * var(--R)), calc(2.1339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="3,-2"] { transform: translate(calc(2.464101615137755 * var(--R)), calc(5.1339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="3,-1"] { transform: translate(calc(3.3301270189221936 * var(--R)), calc(6.6339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="3,0"] { transform: translate(calc(4.196152422706632 * var(--R)), calc(8.1339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="3,1"] { transform: translate(calc(5.06217782649107 * var(--R)), calc(9.6339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="4,-3"] { transform: translate(calc(3.3301270189221928 * var(--R)), calc(3.6339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="4,-2"] { transform: translate(calc(4.196152422706632 * var(--R)), calc(5.1339746 * var(--R))) rotate(-30deg); }
.pointy [data-qr="4,-1"] { transform: translate(calc(5.06217782649107 * var(--R)), calc(6.6339746 * var(--R))) rotate(-30deg); }
.flat [data-qr="6,-3"] { transform: translate(calc(8 * var(--R)), calc(6.9282032 * var(--R))); }
.flat [data-qr="-6,3"] { transform: translate(calc(-10 * var(--R)), calc(6.9282032 * var(--R))); }
.flat [data-qr="-3,-3"] { transform: translate(calc(-5.5 * var(--R)), calc(-0.8660254340599486 * var(--R))); }
.flat [data-qr="3,-6"] { transform: translate(calc(3.5 * var(--R)), calc(-0.8660254340599486 * var(--R))); }
.flat [data-qr="3,3"] { transform: translate(calc(3.5 * var(--R)), calc(14.722431834059948 * var(--R))); }
.flat [data-qr="-3,6"] { transform: translate(calc(-5.5 * var(--R)), calc(14.722431834059948 * var(--R))); }
.flat [data-qr="-1,-4"] { transform: translate(calc(-2.5 * var(--R)), calc(-0.8660254340599477 * var(--R))); }
.flat [data-qr="1,-5"] { transform: translate(calc(0.5 * var(--R)), calc(-0.8660254340599477 * var(--R))); }
.flat [data-qr="4,-5"] { transform: translate(calc(5 * var(--R)), calc(1.7320507772933684 * var(--R))); }
.flat [data-qr="5,-4"] { transform: translate(calc(6.5 * var(--R)), calc(4.3301269886466836 * var(--R))); }
.flat [data-qr="5,-1"] { transform: translate(calc(6.5 * var(--R)), calc(9.526279411353315 * var(--R))); }
.flat [data-qr="4,1"] { transform: translate(calc(5 * var(--R)), calc(12.124355622706632 * var(--R))); }
.flat [data-qr="1,4"] { transform: translate(calc(0.5 * var(--R)), calc(14.722431834059947 * var(--R))); }
.flat [data-qr="-1,5"] { transform: translate(calc(-2.5 * var(--R)), calc(14.722431834059947 * var(--R))); }
.flat [data-qr="-4,5"] { transform: translate(calc(-7 * var(--R)), calc(12.12435562270663 * var(--R))); }
.flat [data-qr="-5,4"] { transform: translate(calc(-8.5 * var(--R)), calc(9.526279411353316 * var(--R))); }
.flat [data-qr="-5,1"] { transform: translate(calc(-8.5 * var(--R)), calc(4.330126988646684 * var(--R))); }
.flat [data-qr="-4,-1"] { transform: translate(calc(-7 * var(--R)), calc(1.7320507772933675 * var(--R))); }
.flat [data-qr="-5,2"] { transform: translate(calc(-8.5 * var(--R)), calc(6.062177796215561 * var(--R))); }
.flat [data-qr="-5,3"] { transform: translate(calc(-8.5 * var(--R)), calc(7.794228603784438 * var(--R))); }
.flat [data-qr="-2,-3"] { transform: translate(calc(-4 * var(--R)), calc(-3.0275509321775473e-8 * var(--R))); }
.flat [data-qr="-3,-2"] { transform: translate(calc(-5.5 * var(--R)), calc(0.866025373508929 * var(--R))); }
.flat [data-qr="2,-5"] { transform: translate(calc(2 * var(--R)), calc(-3.0275509321775473e-8 * var(--R))); }
.flat [data-qr="3,-5"] { transform: translate(calc(3.5 * var(--R)), calc(0.8660253735089299 * var(--R))); }
.flat [data-qr="5,-3"] { transform: translate(calc(6.5 * var(--R)), calc(6.06217779621556 * var(--R))); }
.flat [data-qr="5,-2"] { transform: translate(calc(6.5 * var(--R)), calc(7.794228603784438 * var(--R))); }
.flat [data-qr="3,2"] { transform: translate(calc(3.5 * var(--R)), calc(12.99038102649107 * var(--R))); }
.flat [data-qr="2,3"] { transform: translate(calc(2 * var(--R)), calc(13.856406430275507 * var(--R))); }
.flat [data-qr="-3,5"] { transform: translate(calc(-5.5 * var(--R)), calc(12.99038102649107 * var(--R))); }
.flat [data-qr="-2,5"] { transform: translate(calc(-4 * var(--R)), calc(13.856406430275507 * var(--R))); }
.flat [data-qr="4,0"] { transform: translate(calc(5 * var(--R)), calc(10.392304815137754 * var(--R))); }
.flat [data-qr="-4,0"] { transform: translate(calc(-7 * var(--R)), calc(3.464101584862245 * var(--R))); }
.flat [data-qr="-4,4"] { transform: translate(calc(-7 * var(--R)), calc(10.392304815137754 * var(--R))); }
.flat [data-qr="0,-4"] { transform: translate(calc(-1 * var(--R)), calc(-3.0275509321775473e-8 * var(--R))); }
.flat [data-qr="0,4"] { transform: translate(calc(-1 * var(--R)), calc(13.856406430275507 * var(--R))); }
.flat [data-qr="4,-4"] { transform: translate(calc(5 * var(--R)), calc(3.464101584862245 * var(--R))); }
.flat [data-qr="0,0"] { transform: translate(calc(-1 * var(--R)), calc(6.9282032 * var(--R))); }
.flat [data-qr="-4,1"] { transform: translate(calc(-7 * var(--R)), calc(5.196152392431122 * var(--R))); }
.flat [data-qr="-4,2"] { transform: translate(calc(-7 * var(--R)), calc(6.9282032 * var(--R))); }
.flat [data-qr="-4,3"] { transform: translate(calc(-7 * var(--R)), calc(8.660254007568877 * var(--R))); }
.flat [data-qr="-3,-1"] { transform: translate(calc(-5.5 * var(--R)), calc(2.598076181077807 * var(--R))); }
.flat [data-qr="-3,0"] { transform: translate(calc(-5.5 * var(--R)), calc(4.3301269886466836 * var(--R))); }
.flat [data-qr="-3,1"] { transform: translate(calc(-5.5 * var(--R)), calc(6.062177796215561 * var(--R))); }
.flat [data-qr="-3,2"] { transform: translate(calc(-5.5 * var(--R)), calc(7.794228603784438 * var(--R))); }
.flat [data-qr="-3,3"] { transform: translate(calc(-5.5 * var(--R)), calc(9.526279411353316 * var(--R))); }
.flat [data-qr="-3,4"] { transform: translate(calc(-5.5 * var(--R)), calc(11.258330218922191 * var(--R))); }
.flat [data-qr="-2,-2"] { transform: translate(calc(-4 * var(--R)), calc(1.7320507772933675 * var(--R))); }
.flat [data-qr="-2,-1"] { transform: translate(calc(-4 * var(--R)), calc(3.464101584862245 * var(--R))); }
.flat [data-qr="-2,0"] { transform: translate(calc(-4 * var(--R)), calc(5.196152392431122 * var(--R))); }
.flat [data-qr="-2,1"] { transform: translate(calc(-4 * var(--R)), calc(6.9282032 * var(--R))); }
.flat [data-qr="-2,2"] { transform: translate(calc(-4 * var(--R)), calc(8.660254007568877 * var(--R))); }
.flat [data-qr="-2,3"] { transform: translate(calc(-4 * var(--R)), calc(10.392304815137754 * var(--R))); }
.flat [data-qr="-2,4"] { transform: translate(calc(-4 * var(--R)), calc(12.124355622706632 * var(--R))); }
.flat [data-qr="-1,-3"] { transform: translate(calc(-2.5 * var(--R)), calc(0.866025373508929 * var(--R))); }
.flat [data-qr="-1,-2"] { transform: translate(calc(-2.5 * var(--R)), calc(2.598076181077807 * var(--R))); }
.flat [data-qr="-1,-1"] { transform: translate(calc(-2.5 * var(--R)), calc(4.3301269886466836 * var(--R))); }
.flat [data-qr="-1,0"] { transform: translate(calc(-2.5 * var(--R)), calc(6.062177796215561 * var(--R))); }
.flat [data-qr="-1,1"] { transform: translate(calc(-2.5 * var(--R)), calc(7.794228603784438 * var(--R))); }
.flat [data-qr="-1,2"] { transform: translate(calc(-2.5 * var(--R)), calc(9.526279411353316 * var(--R))); }
.flat [data-qr="-1,3"] { transform: translate(calc(-2.5 * var(--R)), calc(11.258330218922193 * var(--R))); }
.flat [data-qr="-1,4"] { transform: translate(calc(-2.5 * var(--R)), calc(12.99038102649107 * var(--R))); }
.flat [data-qr="0,-3"] { transform: translate(calc(-1 * var(--R)), calc(1.7320507772933675 * var(--R))); }
.flat [data-qr="0,-2"] { transform: translate(calc(-1 * var(--R)), calc(3.464101584862245 * var(--R))); }
.flat [data-qr="0,-1"] { transform: translate(calc(-1 * var(--R)), calc(5.196152392431122 * var(--R))); }
.flat [data-qr="0,1"] { transform: translate(calc(-1 * var(--R)), calc(8.660254007568877 * var(--R))); }
.flat [data-qr="0,2"] { transform: translate(calc(-1 * var(--R)), calc(10.392304815137754 * var(--R))); }
.flat [data-qr="0,3"] { transform: translate(calc(-1 * var(--R)), calc(12.124355622706632 * var(--R))); }
.flat [data-qr="1,-4"] { transform: translate(calc(0.5 * var(--R)), calc(0.866025373508929 * var(--R))); }
.flat [data-qr="1,-3"] { transform: translate(calc(0.5 * var(--R)), calc(2.598076181077806 * var(--R))); }
.flat [data-qr="1,-2"] { transform: translate(calc(0.5 * var(--R)), calc(4.3301269886466836 * var(--R))); }
.flat [data-qr="1,-1"] { transform: translate(calc(0.5 * var(--R)), calc(6.062177796215561 * var(--R))); }
.flat [data-qr="1,0"] { transform: translate(calc(0.5 * var(--R)), calc(7.794228603784438 * var(--R))); }
.flat [data-qr="1,1"] { transform: translate(calc(0.5 * var(--R)), calc(9.526279411353316 * var(--R))); }
.flat [data-qr="1,2"] { transform: translate(calc(0.5 * var(--R)), calc(11.258330218922191 * var(--R))); }
.flat [data-qr="1,3"] { transform: translate(calc(0.5 * var(--R)), calc(12.99038102649107 * var(--R))); }
.flat [data-qr="2,-4"] { transform: translate(calc(2 * var(--R)), calc(1.7320507772933675 * var(--R))); }
.flat [data-qr="2,-3"] { transform: translate(calc(2 * var(--R)), calc(3.464101584862245 * var(--R))); }
.flat [data-qr="2,-2"] { transform: translate(calc(2 * var(--R)), calc(5.196152392431122 * var(--R))); }
.flat [data-qr="2,-1"] { transform: translate(calc(2 * var(--R)), calc(6.9282032 * var(--R))); }
.flat [data-qr="2,0"] { transform: translate(calc(2 * var(--R)), calc(8.660254007568877 * var(--R))); }
.flat [data-qr="2,1"] { transform: translate(calc(2 * var(--R)), calc(10.392304815137754 * var(--R))); }
.flat [data-qr="2,2"] { transform: translate(calc(2 * var(--R)), calc(12.124355622706632 * var(--R))); }
.flat [data-qr="3,-3"] { transform: translate(calc(3.5 * var(--R)), calc(4.3301269886466836 * var(--R))); }
.flat [data-qr="3,-4"] { transform: translate(calc(3.5 * var(--R)), calc(2.598076181077807 * var(--R))); }
.flat [data-qr="3,-2"] { transform: translate(calc(3.5 * var(--R)), calc(6.062177796215561 * var(--R))); }
.flat [data-qr="3,-1"] { transform: translate(calc(3.5 * var(--R)), calc(7.794228603784438 * var(--R))); }
.flat [data-qr="3,0"] { transform: translate(calc(3.5 * var(--R)), calc(9.526279411353316 * var(--R))); }
.flat [data-qr="3,1"] { transform: translate(calc(3.5 * var(--R)), calc(11.258330218922191 * var(--R))); }
.flat [data-qr="4,-3"] { transform: translate(calc(5 * var(--R)), calc(5.196152392431122 * var(--R))); }
.flat [data-qr="4,-2"] { transform: translate(calc(5 * var(--R)), calc(6.9282032 * var(--R))); }
.flat [data-qr="4,-1"] { transform: translate(calc(5 * var(--R)), calc(8.660254007568877 * var(--R))); }
