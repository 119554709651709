.wrapper {
    --margin: 3%;

    aspect-ratio: 1 / 1;
    position: relative;
    border-radius: 50%;
    box-shadow: 0 0 .5em rgba(0, 0, 0, 0.5);
    background-color: #daa520;
}

.wrapper.alt {
    --margin: 0;
}

.wrapper::before {
    content: "";
    display: block;
    position: absolute;
    top: var(--margin);
    left: var(--margin);
    bottom: var(--margin);
    right: var(--margin);
    border-radius: 50%;
}

.wrapper.p-1::before {
    background: var(--sphere-p-1-color);
}

.wrapper.p-2::before {
    background: var(--sphere-p-2-color);
}

.wrapper.p-3::before {
    background: var(--sphere-p-3-color);
}

.wrapper.p-4::before {
    background: var(--sphere-p-4-color);
}

.wrapper::after {
    content: "";
    display: block;
    position: absolute;
    top: var(--margin);
    left: var(--margin);
    bottom: var(--margin);
    right: var(--margin);
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    /*box-shadow: inset 0 0 1em rgba(0, 0, 0, 1);*/
}

@media (min-width: 600px) {
    .wrapper::after {
        /*box-shadow: inset 0 0 3em rgba(0, 0, 0, 1);*/
    }
}

.wrapper.alt::after {
    /*box-shadow: none;*/
}

.wrapper.p-1::after {
    background-image: url("assets/purple.svg");
    background-size: 70% 70%;
}

.wrapper.p-2::after {
    background-image: url("assets/turquoise.svg");
    background-size: 80% 80%;
}

.wrapper.p-3::after {
    background-image: url("assets/coral.svg");
    background-size: 90% 90%;
}

.wrapper.p-4::after {
    background-image: url("assets/white.svg");
    background-size: 75% 75%;
}
