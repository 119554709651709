.wrap {
    --s: 120px;
    --R: calc(var(--s) / 2);

    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    justify-content: space-around;
    align-content: center;
    overflow: hidden;
    width: calc(100% + calc(var(--spacing) * 4));
    margin-left: calc(var(--spacing) * -2);
    margin-bottom: calc(var(--spacing) * 2);
}

.wrap > div {
    position: relative;
    height: var(--s);
}

.wrap > div > div {
    transform: translateX(-50%) !important;
    height: calc(var(--R) * 2);
}

.wrap > div:nth-child(2) > div {
    transform: translateX(-50%) rotate(-15deg) !important;
}

.wrap > div:nth-child(4) {
    z-index: -1;
}

.wrap > div:nth-child(4) > div {
    transform: translateX(-50%) rotate(30deg) !important;
}

.wrap > div > div::before {
    width: 100%;
    height: 100%;
}
