html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

strong,
b {
    font-weight: 700;
}

.theme-light body {
    --bg-opacity: 0.5;
}

.theme-dark body {
    --bg-opacity: 0.5;
}

body {
    color: var(--main-text-color);
    font-size: 0.875rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.7;
    letter-spacing: 0.01071em;
    touch-action: manipulation;

    background-repeat: no-repeat;
    background-attachment: fixed;
    background-color: var(--main-background-color);

    --dd: transparent;

    --top-left-bg-color: rgba(var(--sphere-p-1-rgb), var(--bg-opacity));
    --top-right-bg-color: rgba(var(--sphere-p-2-rgb), var(--bg-opacity));
    --bottom-right-bg-color: rgba(var(--sphere-p-3-rgb), var(--bg-opacity));
    --bottom-left-bg-color: rgba(var(--sphere-p-4-rgb), var(--bg-opacity));

    background-image: radial-gradient(at 0% 0%, var(--top-left-bg-color) 0px, var(--dd) 50%),
    radial-gradient(at 100% 0%, var(--top-right-bg-color) 0px, var(--dd) 50%),
    radial-gradient(at 100% 100%, var(--bottom-right-bg-color) 0px, var(--dd) 50%),
    radial-gradient(at 0% 100%, var(--bottom-left-bg-color) 0px, var(--dd) 50%);

    transition-duration: 1s;
    transition-property: background-image;
}

body.p-1 {
    --top-left-bg-color: var(--sphere-p-1-color);
    background-image: radial-gradient(at 0% 0%, var(--top-left-bg-color) 0px, var(--dd) 50%);
}

body.p-2 {
    --top-right-bg-color: var(--sphere-p-2-color);
    background-image: radial-gradient(at 100% 0%, var(--top-right-bg-color) 0px, var(--dd) 50%);
}

body.p-3 {
    --bottom-right-bg-color: var(--sphere-p-3-color);
    background-image: radial-gradient(at 100% 100%, var(--bottom-right-bg-color) 0px, var(--dd) 50%);
}

body.p-4 {
    --bottom-left-bg-color: var(--sphere-p-4-color);
    background-image: radial-gradient(at 0% 100%, var(--bottom-left-bg-color) 0px, var(--dd) 50%);
}

body::backdrop {
    background-color: #fafafa;
}

html,
body,
#root {
    height: 100%;
}

#root {
    /*padding: calc(var(--spacing) * 2);*/
}

@media (max-width: 599px) {
    #root {
        /*padding: 0;*/
    }
}
