.item {
    pointer-events: var(--pointer-events);
    position: absolute;
    top: 0;
    left: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transform: translate(var(--X), var(--Y));
    transition-duration: var(--duration);
    transition-property: transform, width, height;
    z-index: -1;
}

.item > div {
    height: auto;
    width: 95%;
}

:global(.flat) .item {
    width: var(--R);
    height: calc(var(--R) * 2 * var(--ratio));
    --x: calc(var(--R) * -0.5);
    --y: calc(var(--R) * var(--ratio));
}

:global(.pointy) .item {
    width: calc(var(--R) * var(--ratio));
    height: calc(var(--R) * 2);
    --x: calc(var(--R) * 0.5 * var(--ratio));
    --y: calc(var(--R) * 0.5);
}

:global(.flat) .item.g-1 {
    --X: calc(var(--x) * 7);
    --Y: 0;
}

:global(.pointy) .item.g-1 {
    --X: calc(var(--x) * -15);
    --Y: calc(var(--y) * 7);
}

:global(.flat) .item.g-2 {
    --X: calc(var(--x) * 10);
    --Y: calc(var(--y));
}

:global(.pointy) .item.g-2 {
    --X: calc(var(--x) * -17);
    --Y: calc(var(--y) * 10);
}

:global(.flat) .item.g-3 {
    --X: calc(var(--x) * -5);
    --Y: 0;
}

:global(.pointy) .item.g-3 {
    --X: calc(var(--x) * -3);
    --Y: calc(var(--y));
}

:global(.flat) .item.g-4 {
    --X: calc(var(--x) * -8);
    --Y: calc(var(--y));
}

:global(.pointy) .item.g-4 {
    --X: calc(var(--x));
    --Y: calc(var(--y));
}

:global(.flat) .item.g-5 {
    --X: calc(var(--x) * -14);
    --Y: calc(var(--y) * 7);
}

:global(.pointy) .item.g-5 {
    --X: calc(var(--x) * 13);
    --Y: calc(var(--y) * 7);
}

:global(.flat) .item.g-6 {
    --X: calc(var(--x) * -14);
    --Y: calc(var(--y) * 9);
}

:global(.pointy) .item.g-6 {
    --X: calc(var(--x) * 15);
    --Y: calc(var(--y) * 10);
}

:global(.flat) .item.g-7 {
    --X: calc(var(--x) * -8);
    --Y: calc(var(--y) * 15);
}

:global(.pointy) .item.g-7 {
    --X: calc(var(--x) * 15);
    --Y: calc(var(--y) * 22);
}

:global(.flat) .item.g-8 {
    --X: calc(var(--x) * -5);
    --Y: calc(var(--y) * 16);
}

:global(.pointy) .item.g-8 {
    --X: calc(var(--x) * 13);
    --Y: calc(var(--y) * 25);
}

:global(.flat) .item.g-9 {
    --X: calc(var(--x) * 7);
    --Y: calc(var(--y) * 16);
}

:global(.pointy) .item.g-9 {
    --X: calc(var(--x) * 1);
    --Y: calc(var(--y) * 31);
}

:global(.flat) .item.g-10 {
    --X: calc(var(--x) * 10);
    --Y: calc(var(--y) * 15);
}

:global(.pointy) .item.g-10 {
    --X: calc(var(--x) * -3);
    --Y: calc(var(--y) * 31);
}

:global(.flat) .item.g-11 {
    --X: calc(var(--x) * 16);
    --Y: calc(var(--y) * 9);
}

:global(.pointy) .item.g-11 {
    --X: calc(var(--x) * -15);
    --Y: calc(var(--y) * 25);
}

:global(.flat) .item.g-12 {
    --X: calc(var(--x) * 16);
    --Y: calc(var(--y) * 7);
}

:global(.pointy) .item.g-12 {
    --X: calc(var(--x) * -17);
    --Y: calc(var(--y) * 22);
}
