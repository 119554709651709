.root {
    composes: box from "../Drawer.module.css";
}

.root button {
    border: 0;
    text-transform: uppercase;
    background-color: transparent;
    color: var(--main-text-color);
    padding: calc(var(--spacing) * 0.5) var(--spacing);
}

.root button:not([disabled]):hover {
    cursor: pointer;
}

.root button[disabled] {
    opacity: var(--disabled-opacity);
}

.root button:first-child {
    border-right: 1px solid var(--main-text-color-disabled);
}
