.drawer {
    --width: var(--drawer-width);

    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: var(--width);
    padding: var(--spacing);
    background-color: var(--main-background-color);
    box-shadow: 0 8px 10px -5px rgb(0 0 0 / 20%), 0 16px 24px 2px rgb(0 0 0 / 14%), 0 6px 30px 5px rgb(0 0 0 / 12%);
    transform: translateX(0);
    transition-duration: var(--duration);
    transition-property: transform, box-shadow;

    display: flex;
    flex-direction: column;
}

.hidden .drawer {
    transform: translateX(calc(var(--width) * -1));
    box-shadow: none;
}

@media (max-width: 599px) {
    .drawer {
        width: 100vw;
    }

    .hidden .drawer {
        transform: translateX(calc(100vw * -1));
        box-shadow: none;
    }
}

.hidden .backdrop {
    opacity: 0;
    visibility: hidden;
}

.backdrop {
    position: fixed;
    inset: 0;
    background-color: var(--main-backdrop-background-color);
    opacity: 1;
    visibility: visible;
    transition-duration: .2s;
    transition-property: opacity, visibility;
    backdrop-filter: blur(calc(var(--spacing) * 2));
}

.content {
    flex: 1;
    overflow: auto;
}

.box {
    padding: var(--spacing);
    border-radius: var(--border-radius);
    margin-bottom: calc(var(--spacing) * 2);
    display: flex;
    justify-content: center;
}

.actions {
    composes: box;
}

.hidden .rulesContainer {
    opacity: 0;
    visibility: hidden;
    top: 10%;
}

.rulesContainer {
    position: fixed;
    top: 0;
    left: var(--drawer-width);
    bottom: 0;
    opacity: 1;
    visibility: visible;
    transition-duration: .2s;
    transition-property: opacity, visibility, top;
    max-width: calc(var(--spacing) * 100);
    padding: calc(var(--spacing) * 5);
    overflow: auto;
}
