.playersWrapper {
    margin: calc(var(--spacing) * 30) auto calc(var(--spacing) * 10);
    display: grid;
    gap: calc(var(--spacing) * 3);
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.playersWrapper >:last-child {
    /*plus-light*/
}

.actionsWrapper {
    padding: var(--spacing);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.startGameButton {
    composes: main from "../Components/Button/Button.module.css";
    composes: withIcon from "../Components/Button/Button.module.css";
}

.startGameButton::after {
    --size: var(--icon-size);
    background-image: var(--play-icon);
}

.cancelButton {
    composes: text from "../Components/Button/Button.module.css";
    composes: withIcon from "../Components/Button/Button.module.css";
}

.cancelButton::before {
    --size: var(--icon-size);
    background-image: var(--left-arrow-icon);
}
